import React, {useEffect, useState} from "react";
import axios from "axios";
import useUserStore from "../store/userStore";
import {API_URL, telegramLink, twitterLink} from "../constants";
import {getExtraInformation, getTwitterFollow, getTwitterGrow, getTwitterVote} from "../services";
import {formatNumberWithCommas, tweet} from "../helpers";
import ButtonLarge from "../components/ButtonLarge";
import Preloader from "../components/Preloader";
import earnPointsDataOld from "../assets/earn-points-old.json";
import earnPointsData from "../assets/earn-points.json";
import sprite from "../assets/sprite.svg";

// {
//   "id": "1",
//   "title": "Join hamster republic",
//   "points": 10000,
//   "platform": "hamster",
//   "icon": "../images/hamster.png",
//   "buttonTitle": "Join",
//   "status": "active"
// },
// {
//   "id": "5",
//   "title": "Follow our instagram",
//   "points": 20000,
//   "platform": "instagram",
//   "icon": "../images/instagram-icon.png",
//   "buttonTitle": "Follow",
//   "status": "active"
// }

const HAMSTER_LINK = 'https://short.whackem.io/Hamster_Rep-RC'

const EarnPointsScreen = () => {
  const {isElectionsFinished} = useUserStore();
  const tweetTextCondition = isElectionsFinished
    ? "Join the Whack’em community and earn up to 30,000 Airdrop Points instantly! 🎮 Start Whacking, complete challenges, and invite friends to grow!"
    : "Think you know the next US President? 🗳️ Cast your vote, earn up to 30,000 points, and watch the grand prize grow with every vote cast!";
  const {user, fetchUserOrCreate} = useUserStore();
  const [earnPointsOld, setEarnPointsOld] = useState([]);
  const [earnPoints, setEarnPoints] = useState([]);
  const [isUserSubscribedOnTelegram, setIsUserSubscribedOnTelegram] = useState(false);
  const [isUserSharedOnX, setIsUserSharedOnX] = useState(null);
  const [isUserSubscribedOnX, setIsUserSubscribedOnX] = useState(null);
  const [isUserJoinedHamster, setIsUserJoinedHamster] = useState(null);
  const [isUserGrowOnX, setIsUserGrowOnX] = useState(null);
  const [loading, setLoading] = useState(true);
  const initData = window.Telegram?.WebApp?.initData;

  const gameUrl = process.env.REACT_APP_GAME_URL;
  const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
  const text = "Join the Whack’em TON Mini Game and claim yourself a FREE bag of 20,000 Airdrop Points instantly! Join now and have a chance to get your slice of the Grand Prize that is now over 20 MILLION Airdrop Points!";
  const encodedText = encodeURIComponent(text);
  const fullUrl = `${gameUrl}?startapp=ref_${userId}`;

  useEffect(() => {
    (async () => {
      if (initData) {
        await fetchUserOrCreate(initData);
      } else {
        console.error('Telegram Web App script not loaded');
      }
    })();
  }, [fetchUserOrCreate, initData]);

  useEffect(() => {
    (async () => {
      const extraInformationData = await getExtraInformation();
      setIsUserSubscribedOnTelegram(extraInformationData.isUserSubscribedOnTelegram);
      setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
      setIsUserSubscribedOnX(extraInformationData.isUserFollowOnX);
      setIsUserJoinedHamster(extraInformationData.isUserJoinedHamster)
      setIsUserGrowOnX(extraInformationData.isUserGrowOnX);
      setLoading(false);
    })();

    setEarnPointsOld(earnPointsDataOld);
    setEarnPoints(earnPointsData);
  }, [isUserSharedOnX, isUserSubscribedOnX]);

  const handleTweetVote = async () => {
    if (user) {
      tweet(tweetTextCondition, gameUrl, userId, user.generatedUserId);
      await getTwitterVote();
      const extraInformationData = await getExtraInformation();
      setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
    }
  };

  const handleTweetGrow = async () => {
    if (user) {
      tweet(tweetTextCondition, gameUrl, userId, user.generatedUserId);
      await getTwitterGrow();
      const extraInformationData = await getExtraInformation();
      setIsUserGrowOnX(extraInformationData.isUserGrowOnX);
    }
  };

  const handleFollowX = async () => {
    window?.Telegram?.WebApp?.openLink(twitterLink);
    await getTwitterFollow();
    const extraInformationData = await getExtraInformation();
    setIsUserSubscribedOnX(extraInformationData.isUserFollowOnX);
  };

  const handleClaimClick = async (id, platform) => {
    if (platform !== "refer") {
      try {
        const response = await axios.request({
          method: 'POST',
          baseURL: API_URL,
          url: 'telegram-users/generate-social-media-reward',
          data: { platform },
          headers: {
            initdata: initData,
            'ngrok-skip-browser-warning': '69420'
          },
        });
        if (response.data.success) {
          const extraInformationData = await getExtraInformation();

          switch(platform) {
            case "telegram":
              window?.Telegram?.WebApp?.openTelegramLink(telegramLink);
              break;
            case "x-vote":
              setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
              break;
            case "x-grow":
              setIsUserGrowOnX(extraInformationData.isUserGrowOnX);
              break;
            case "twitter":
              setIsUserSubscribedOnX(extraInformationData.isUserFollowOnX);
              break;
            case "hamster":
              setIsUserJoinedHamster(extraInformationData.isUserJoinedHamster);
              window?.Telegram?.WebApp?.openLink(HAMSTER_LINK);
              break;
            default:
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  if (!earnPoints || loading) {
    return <Preloader />;
  }

  return (
    isElectionsFinished ? (
      <div className="earn-points-container">
        <ul>
          {earnPoints.map((item) => (
            (item.platform !== 'twitter' || (isUserGrowOnX !== null && isUserGrowOnX !== 'pending')) && (
              <li
                key={item.id}
                className="earn-points-item"
              >
                {item.status === 'inactive' && (<div className="earn-points-item-inactive"/>)}
                <div className="earn-points-bar">
                  <img src={item.icon} alt="icon"/>
                </div>
                <div className="earn-points-content">
                  <h3 className="earn-points-title">{item.title}</h3>
                  <span className="earn-points-value" data-content={`+${formatNumberWithCommas(item.points)} Pts`}>
                  +{formatNumberWithCommas(item.points)} Pts
                </span>
                  {item.status === 'inactive' ? (
                    <ButtonLarge additionalClass={`button-inactive ${isUserSharedOnX === 'done' ? 'button-done' : ''}`}>
                      {isUserSharedOnX === 'done' ? 'Done' : 'Expired'}
                    </ButtonLarge>
                  ) : (
                    <>
                    {item.platform === 'telegram' && (
                      <ButtonLarge
                        onClick={() => handleClaimClick(item.id, item.platform)}
                        additionalClass={isUserSubscribedOnTelegram ? "button-done" : ""}
                      >
                        {isUserSubscribedOnTelegram ? 'Done' : item.buttonTitle}
                      </ButtonLarge>
                    )}

                    {item.platform === 'x-vote' && (
                      isUserSharedOnX === 'claim' || isUserSharedOnX === 'done' ? (
                        <ButtonLarge
                          onClick={() => handleClaimClick(item.id, item.platform)}
                          additionalClass={isUserSharedOnX === "done" ? "button-done" : ""}
                        >
                          {isUserSharedOnX === 'claim' ? 'Claim' : 'Done'}
                        </ButtonLarge>
                      ) : (
                        <ButtonLarge
                          onClick={handleTweetVote}
                          additionalClass={isUserSharedOnX === 'pending' ? 'button-pending' : ''}
                        >
                          {isUserSharedOnX === 'pending' ? 'Pending' : item.buttonTitle}
                        </ButtonLarge>
                      )
                    )}

                    {item.platform === 'x-grow' && (
                      isUserGrowOnX === 'claim' || isUserGrowOnX === 'done' ? (
                        <ButtonLarge
                          onClick={() => handleClaimClick(item.id, item.platform)}
                          additionalClass={isUserGrowOnX === "done" ? "button-done" : ""}
                        >
                          {isUserGrowOnX === 'claim' ? 'Claim' : 'Done'}
                        </ButtonLarge>
                      ) : (
                        <ButtonLarge
                          onClick={handleTweetGrow}
                          additionalClass={isUserGrowOnX === 'pending' ? 'button-pending' : ''}
                        >
                          {isUserGrowOnX === 'pending' ? 'Pending' : item.buttonTitle}
                        </ButtonLarge>
                      )
                    )}

                    {item.platform === 'hamster' && (
                      isUserJoinedHamster ? (
                        <ButtonLarge
                          onClick={() => handleClaimClick(item.id, item.platform)}
                          additionalClass={isUserJoinedHamster ? "button-done" : ""}
                        >
                          Done
                        </ButtonLarge>
                      ) : (
                        <div className="button-wrap">
                          <ButtonLarge
                            onClick={() => handleClaimClick(item.id, item.platform)}
                          >
                            {item.buttonTitle}
                          </ButtonLarge>
                        </div>
                      )
                    )}

                    {item.platform === 'twitter' && (
                      isUserSubscribedOnX === 'claim' || isUserSubscribedOnX === 'done' ? (
                        <ButtonLarge
                          onClick={() => handleClaimClick(item.id, item.platform)}
                          additionalClass={isUserSubscribedOnX === "done" ? "button-done" : ""}
                        >
                          {isUserSubscribedOnX === 'claim' ? 'Claim' : 'Done'}
                        </ButtonLarge>
                      ) : (
                        <ButtonLarge
                          onClick={handleFollowX}
                          additionalClass={isUserSubscribedOnX === 'pending' ? 'button-pending' : ''}
                          disabled={isUserGrowOnX === null || isUserGrowOnX === 'pending'}
                        >
                          {isUserSubscribedOnX === 'pending' ? 'Pending' : item.buttonTitle}
                        </ButtonLarge>
                      )
                    )}

                    {item.platform === 'refer' && (
                      <a href={`https://t.me/share/url?url=${encodeURIComponent(fullUrl)}&text=${encodedText}`}>
                        <ButtonLarge>Refer Friends</ButtonLarge>
                      </a>
                    )}
                  </>)}


                  {item.platform === 'x-vote' && (
                    <div className="earn-points-msg">
                      <svg height="20" width="20">
                        <use href={`${sprite}#alert`}></use>
                      </svg>
                      {isUserSharedOnX === 'pending' ? (
                        <p>Credit can take up to 70 minutes</p>
                      ) : (
                        <p>Modifying the Tweet may delay your points credit or make you ineligible to receive them</p>
                      )}
                    </div>
                  )}
                  {item.platform === 'x-grow' && (isUserGrowOnX === null || isUserGrowOnX === 'pending') && (
                    <div className="earn-points-msg">
                      <svg height="20" width="20">
                        <use href={`${sprite}#alert`}></use>
                      </svg>
                      {isUserGrowOnX === null && (
                        <p>Modifying the Tweet may delay your points credit or make you ineligible to receive them</p>
                      )}
                      {isUserGrowOnX === 'pending' && (
                        <p>Credit can take up to 70 minutes</p>
                      )}
                    </div>
                  )}
                  {item.platform === 'twitter' && isUserSubscribedOnX === 'pending' && (
                    <div className="earn-points-msg">
                      <svg height="20" width="20">
                        <use href={`${sprite}#alert`}></use>
                      </svg>
                      <p>Credit can take up to 70 minutes</p>
                    </div>
                  )}
                </div>
              </li>
            )
          ))}
        </ul>
      </div>
    ) : (
      <div className="earn-points-container">
        <ul>
          {earnPointsOld.map((item) => (
            (item.platform !== 'twitter' || (isUserSharedOnX !== null && isUserSharedOnX !== 'pending')) && (
              <li
                key={item.id}
                className="earn-points-item"
              >
                {item.status === 'inactive' && (<div className="earn-points-item-inactive"/>)}
                <div className="earn-points-bar">
                  <img src={item.icon} alt="icon"/>
                </div>
                <div className="earn-points-content">
                  <h3 className="earn-points-title">{item.title}</h3>
                  <span className="earn-points-value" data-content={`+${formatNumberWithCommas(item.points)} Pts`}>
                  +{formatNumberWithCommas(item.points)} Pts
                </span>
                  {item.status === 'inactive' ? (<ButtonLarge additionalClass="button-inactive">
                    Expired
                  </ButtonLarge>) : (<>
                    {item.platform === 'telegram' && (
                      <ButtonLarge
                        onClick={() => handleClaimClick(item.id, item.platform)}
                        additionalClass={isUserSubscribedOnTelegram ? "button-done" : ""}
                      >
                        {isUserSubscribedOnTelegram ? 'Done' : item.buttonTitle}
                      </ButtonLarge>
                    )}

                    {item.platform === 'x-vote' && (
                      isUserSharedOnX === 'claim' || isUserSharedOnX === 'done' ? (
                        <ButtonLarge
                          onClick={() => handleClaimClick(item.id, item.platform)}
                          additionalClass={isUserSharedOnX === "done" ? "button-done" : ""}
                        >
                          {isUserSharedOnX === 'claim' ? 'Claim' : 'Done'}
                        </ButtonLarge>
                      ) : (
                        <ButtonLarge
                          onClick={handleTweetVote}
                          additionalClass={isUserSharedOnX === 'pending' ? 'button-pending' : ''}
                        >
                          {isUserSharedOnX === 'pending' ? 'Pending' : item.buttonTitle}
                        </ButtonLarge>
                      )
                    )}

                    {item.platform === 'hamster' && (
                      isUserJoinedHamster ? (
                        <ButtonLarge
                          onClick={() => handleClaimClick(item.id, item.platform)}
                          additionalClass={isUserJoinedHamster ? "button-done" : ""}
                        >
                          Done
                        </ButtonLarge>
                      ) : (
                        <div className="button-wrap">
                          <ButtonLarge
                            onClick={() => handleClaimClick(item.id, item.platform)}
                          >
                            {item.buttonTitle}
                          </ButtonLarge>
                        </div>
                      )
                    )}

                    {item.platform === 'twitter' && (
                      isUserSubscribedOnX === 'claim' || isUserSubscribedOnX === 'done' ? (
                        <ButtonLarge
                          onClick={() => handleClaimClick(item.id, item.platform)}
                          additionalClass={isUserSubscribedOnX === "done" ? "button-done" : ""}
                        >
                          {isUserSubscribedOnX === 'claim' ? 'Claim' : 'Done'}
                        </ButtonLarge>
                      ) : (
                        <ButtonLarge
                          onClick={handleFollowX}
                          additionalClass={isUserSubscribedOnX === 'pending' ? 'button-pending' : ''}
                          disabled={isUserSharedOnX === null || isUserSharedOnX === 'pending'}
                        >
                          {isUserSubscribedOnX === 'pending' ? 'Pending' : item.buttonTitle}
                        </ButtonLarge>
                      )
                    )}

                    {item.platform === 'refer' && (
                      <a href={`https://t.me/share/url?url=${encodeURIComponent(fullUrl)}&text=${encodedText}`}>
                        <ButtonLarge>Refer Friends</ButtonLarge>
                      </a>
                    )}
                  </>)}

                  {item.platform === 'x-vote' && (
                    <div className="earn-points-msg">
                      <svg height="20" width="20">
                        <use href={`${sprite}#alert`}></use>
                      </svg>
                      {isUserSharedOnX === 'pending' ? (
                        <p>Credit can take up to 70 minutes</p>
                      ) : (
                        <p>Modifying the Tweet may delay your points credit or make you ineligible to receive them</p>
                      )}
                    </div>
                  )}
                  {item.platform === 'twitter' && isUserSubscribedOnX === 'pending' && (
                    <div className="earn-points-msg">
                      <svg height="20" width="20">
                        <use href={`${sprite}#alert`}></use>
                      </svg>
                      <p>Credit can take up to 70 minutes</p>
                    </div>
                  )}
                </div>
              </li>
            )
          ))}
        </ul>
      </div>
    )
  );
};

export default EarnPointsScreen;
