import React, {useEffect, useState} from "react";
import {getUserPhoto} from "../services";

const Avatar = ({
  firstName = '',
  lastName = '',
  username = '',
  size,
  team,
  userId
}) => {
  const borderColor = team === "red" ? "#EF2424" : "#000AFF";
  const backgroundColor = team === "red" ? "#f09999" : "#9dc9f2";
  const getFirstLetter = (str) => str.trim().match(/[a-zA-Zа-яА-Я]/)?.[0] || '';
  const initials = (firstName && lastName)
    ? `${getFirstLetter(firstName)}${getFirstLetter(lastName)}`
    : getFirstLetter(firstName || lastName || username);
  const fontSize = size / 100 * 45;
  const [userPhoto, setUserPhoto] = useState('');

  useEffect(() => {
    const fetchUserPhoto = async () => {
      try {
        const photoUrl = await getUserPhoto(userId);
        setUserPhoto(photoUrl);
      } catch (error) {
        console.error("Error fetching telegram user photo data:", error);
      }
    };
    fetchUserPhoto();
  }, [userId]);
  return (
    <div className="user-avatar" style={{width: size, height: size, border: `2px solid ${borderColor}`}}>
      {userPhoto ? (
        <img src={userPhoto} alt="User"/>
      ) : (
        <div
          className="user-avatar-placeholder"
          style={{backgroundColor: `${backgroundColor}`, fontSize: `${fontSize}px`}}
        >
          <span style={{color: `${borderColor}`}}>{initials}</span>
        </div>
      )}
    </div>

  );
};

export default Avatar;
