import {NavLink, Outlet, useLocation} from "react-router-dom";
import sprite from "../assets/sprite.svg";

const bottomMenu = [
  {
    path: "earn-points",
    title: "Earn Points",
    icon: {
      id: "earn-points",
      height: 24,
      width: 24
    }
  },
  {
    path: "account",
    title: "Account",
    icon: {
      id: "account",
      height: 22,
      width: 22
    }
  },
  {
    path: "wallet",
    title: "Wallet",
    icon: {
      id: "wallet",
      height: 20,
      width: 20
    }
  },
  {
    path: "bet-win",
    title: "Bet/Win",
    icon: {
      id: "bet-win",
      height: 21,
      width: 22
    }
  },
  {
    path: "seed-ifo",
    title: "Seed IFO",
    icon: {
      id: "seed-ifo",
      height: 22,
      width: 22
    }
  }
]

const Tabs = () => {
  const location = useLocation();
  const isBetWinRoute = location.pathname.startsWith('/tabs/bet-win');

  return (
    <div className={`tabs-container ${location.pathname === '/tabs/game' ? 'game-container' : 'scores-container'}`}>
      {isBetWinRoute ? (
        <nav className="tabs-router white" style={{display: 'none'}}>
          <ul>
            <li>
              <NavLink
                style={{pointerEvents: 'none', opacity: 0}}
                to="/tabs/bet-win"
                className={({ isActive }) => isActive ? "active" : ""}
              >
                <svg height="24" width="24">
                  <use href={`${sprite}#game`}></use>
                </svg>
                Markets
              </NavLink>
            </li>
            <li>
              <NavLink
                style={{pointerEvents: 'none', opacity: 0}}
                to="/tabs/portfolio"
                className={({ isActive }) => isActive ? "active" : ""}
              >
                <svg height="24" width="24">
                  <use href={`${sprite}#high-scores`}></use>
                </svg>
                Portfolio
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : (
          <nav className="tabs-router">
            <ul>
              <li>
                <NavLink
                  to="game"
                  className={({isActive}) => isActive ? "active" : ""}
                >
                  <svg height="24" width="24">
                    <use href={`${sprite}#game`}></use>
                  </svg>
                  Play Game
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="high-scores"
                  className={({isActive}) => isActive ? "active" : ""}
                >
                  <svg height="24" width="24">
                    <use href={`${sprite}#high-scores`}></use>
                  </svg>
                  High Scores
                </NavLink>
              </li>
            </ul>
          </nav>
      )}
      <Outlet/>
      <nav className="tabs-router bottom-menu">
        <ul>
          {bottomMenu.map((item, index) => (
            <li
              key={item.title}
              style={{width: `calc(100% / ${bottomMenu.length})`}}
            >
              <NavLink
                to={item.path}
                className={({isActive}) => isActive ? "active" : ""}
              >
                <div className="svg-wrap">
                  <svg height={item.icon.height} width={item.icon.width}>
                    <use href={`${sprite}#${item.icon.id}`}></use>
                  </svg>
                </div>
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Tabs;
