import React from 'react';
import {instagramLink, telegramLink, twitterLink} from "../constants";
import {FaInstagram, FaTelegram} from 'react-icons/fa';
import {AiFillTwitterCircle} from "react-icons/ai";
import tonIcon from "../assets/ton-icon.png";
import useUserStore from "../store/userStore";

const Footer = () => {
  const {isElectionsFinished} = useUserStore();

  return (
    <footer className="footer">
      <h1 className="footer-title">Whack'Em Token</h1>
      <h2 className="footer-subtitle">to be launched on</h2>
      <div className="footer-ton-logo">
        <img src={tonIcon} alt="Ton Logo"/>
      </div>
      <h3 className="footer-subtitle2">Stay Connected</h3>
      <div className="social-icons">
        {isElectionsFinished && (
          <a className="instagram" target='_blank' href={instagramLink} rel="noreferrer">
            <FaInstagram size={22} color={'white'}/>
          </a>
        )}
        <a target='_blank' href={telegramLink} rel="noreferrer">
          <FaTelegram/>
        </a>
        <a target='_blank' href={twitterLink} rel="noreferrer">
          <AiFillTwitterCircle size={35}/>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
