import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {formatNumberWithCommas, tweet} from "../helpers";
import ButtonLarge from "./ButtonLarge";
import {getExtraInformation, getTwitterVote} from "../services";
import axios from "axios";
import {API_URL} from "../constants";
import useUserStore from "../store/userStore";
import sprite from "../assets/sprite.svg";

const TWEET_POINTS = 20000;

const VoteTweet = ({generatedUserId}) => {
  const {isElectionsFinished} = useUserStore();
  const tweetTextCondition = isElectionsFinished
    ? "Join the Whack’em community and earn up to 30,000 Airdrop Points instantly! 🎮 Start Whacking, complete challenges, and invite friends to grow!"
    : "Think you know the next US President? 🗳️ Cast your vote, earn up to 30,000 points, and watch the grand prize grow with every vote cast!";
  const [isUserSharedOnX, setIsUserSharedOnX] = useState(null);
  const navigate = useNavigate();
  const initData = window.Telegram?.WebApp?.initData;
  const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
  const gameUrl = process.env.REACT_APP_GAME_URL;

  useEffect(() => {
    (async () => {
      const extraInformationData = await getExtraInformation();
      setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
    })();
  }, []);

  const handleTweet = async () => {
    tweet(tweetTextCondition, gameUrl, userId, generatedUserId, navigate);
    await getTwitterVote();
    const extraInformationData = await getExtraInformation();
    setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
  };

  const handleClaimClick = async (id, platform) => {
    try {
      const response = await axios.request({
        method: 'POST',
        baseURL: API_URL,
        url: 'telegram-users/generate-social-media-reward',
        data: { platform: 'x-vote' },
        headers: {
          initdata: initData,
          'ngrok-skip-browser-warning': '69420'
        },
      });
      if (response.data.success) {
        const extraInformationData = await getExtraInformation();
        setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  return (
    isElectionsFinished ? (
      <div className="vote-tweet earn-points-item">
        <div className="earn-points-bar">
          <img src="../images/x-vote-icon.png" alt="icon"/>
        </div>
        <div className="earn-points-content">
          <h2>EARN MORE AND HELP<br/> GROW OUR COMMUNITY</h2>
          <h3 className="earn-points-title">TWEET AND EARN</h3>
          <span className="earn-points-value" data-content={`+${formatNumberWithCommas(TWEET_POINTS)} Pts`}>
          +{formatNumberWithCommas(TWEET_POINTS)} Pts
        </span>
          {isUserSharedOnX === 'claim' || isUserSharedOnX === 'done' ? (
            <ButtonLarge
              onClick={handleClaimClick}
              additionalClass={isUserSharedOnX === "done" ? "button-done" : ""}
            >
              {isUserSharedOnX === 'claim' ? 'Claim' : 'Done'}
            </ButtonLarge>
          ) : (
            <ButtonLarge
              onClick={handleTweet}
              additionalClass={isUserSharedOnX === 'pending' ? 'button-pending' : ''}
            >
              {isUserSharedOnX === 'pending' ? 'Pending' : 'Tweet'}
            </ButtonLarge>
          )}
          <div className="earn-points-msg" style={{maxWidth: '206px'}}>
            <svg height="20" width="20">
              <use href={`${sprite}#alert`}></use>
            </svg>
            <p style={{textAlign: 'left'}}>Modifying the Tweet may delay your points credit or make you ineligible to
              receive them</p>
          </div>
        </div>
      </div>
    ) : (
      <div className="vote-tweet earn-points-item">
        <div className="earn-points-bar">
          <img src="../images/x-vote-icon.png" alt="icon"/>
        </div>
        <div className="earn-points-content">
          <h2>EARN MORE AND HELP<br/> GROW THE GRAND PRIZE</h2>
          <h3 className="earn-points-title">TWEET AND EARN</h3>
          <span className="earn-points-value" data-content={`+${formatNumberWithCommas(25000)} Pts`}>
          +{formatNumberWithCommas(25000)} Pts
        </span>
          {isUserSharedOnX === 'claim' || isUserSharedOnX === 'done' ? (
            <ButtonLarge
              onClick={handleClaimClick}
              additionalClass={isUserSharedOnX === "done" ? "button-done" : ""}
            >
              {isUserSharedOnX === 'claim' ? 'Claim' : 'Done'}
            </ButtonLarge>
          ) : (
            <ButtonLarge
              onClick={handleTweet}
              additionalClass={isUserSharedOnX === 'pending' ? 'button-pending' : ''}
            >
              {isUserSharedOnX === 'pending' ? 'Pending' : 'Tweet'}
            </ButtonLarge>
          )}
          <div className="earn-points-msg" style={{maxWidth: '206px'}}>
            <svg height="20" width="20">
              <use href={`${sprite}#alert`}></use>
            </svg>
            <p style={{textAlign: 'left'}}>Modifying the Tweet may delay your points credit or make you ineligible to receive them</p>
          </div>
        </div>
      </div>
    )
  );
};

export default VoteTweet;
