import React, {useEffect} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import {API_URL} from "../constants";
import Footer from '../components/Footer';
import mainImage from '../assets/trump_handup.png';
import useUserStore from "../store/userStore";

const CongratulationsScreen = () => {
  const {isElectionsFinished} = useUserStore();
  const {user, fetchUserOrCreate} = useUserStore();
  const initData = window.Telegram?.WebApp?.initData;
  const playGameLink = user && user.isVote ? "/tabs/game" : "/select-party";

  useEffect(() => {
    (async () => {
      if (initData) {
        await fetchUserOrCreate(initData);
      } else {
        console.error('Telegram Web App script not loaded');
      }
    })();
  }, [fetchUserOrCreate, initData]);

  useEffect(() => {
    if (initData) {
      markAsGreeted(initData);
    } else {
      console.error("Telegram Web App script not loaded");
    }
  }, [initData]);

  const markAsGreeted = async (initDate) => {
    try {
      await axios.request({
        method: 'GET',
        baseURL: API_URL,
        url: '/telegram-users/mark-as-greeted',
        headers: {
          initdata: initDate,
          'ngrok-skip-browser-warning': '69420'
        }
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <div>
      <div className="container blur">
        <div className="task-cointainer">
          <h3>WHACK’EM Holder</h3>
          <p>Complete tasks and accumulate points even before
          the launch of our game</p>
        </div>
        <div className="subscribe-container">
          <h2>Whack’Em VIP Lounge</h2>
          <div className="white-container">
            <p>To access our VIP Lounge, subscribe to the Whack’em Telegram channel</p>
            <button className="orange-button">Subscribe</button>
            <Link to="/congratulations"><button className="blue-button">Verify Subscription</button></Link>
          </div>
          <p className="hint">Complete this task to unlock our VIP room 
          and access additional reward points</p>
        </div>
        <Footer />
      </div>
      <div className="modal">
        <div className="image-container" id="congratulation-image">
          <img src={mainImage} alt="Trump" className="main-image" />
        </div>
        <div className="main-container">
          <div className="congratulation-container">
            <h1>Congratulations!</h1>
            {isElectionsFinished ? (
              <>
                <p>You’ll get 10,000 Airdrop Points after verification is done. In the meantime, jump in and start
                  Whacking away to rack up more rewards!</p>
                <Link to="/select-party">
                  <button>{isElectionsFinished ? "Get My Whack On!" : "Cast My Vote"}</button>
                </Link>
              </>
            ) : (
              <>
                <p>Once verification is done, you'll earn 10,000 Airdrop Points and be officially registered as an
                  in-game Voter eligible to cast your vote for an additional 10,000 Airdrop Points!</p>
                <Link to={playGameLink}>
                  <button>Cast My Vote</button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsScreen;
