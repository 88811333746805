import seedIfo from "../assets/seed-ifo.png";
import React from "react";

const SeedIfoScreen = () => {
  return (
    <div className="seed-ifo">
      <img className="seed-ifo-img" src={seedIfo} alt="Seed Ifo" />
    </div>
  );
};

export default SeedIfoScreen;
