import Avatar from "./Avatar";
import {getDisplayName} from "../helpers";

const UserItem = ({
  firstName,
  lastName,
  name,
  rank,
  points,
  team,
  userId,
  mainUser = false,
  element: Tag = 'li'
}) => {
  const displayName = getDisplayName(firstName, lastName, name);

  return (
    <Tag className="user-item">
      <div className="user-item-left">
        <Avatar
          firstName={firstName}
          lastName={lastName}
          size={45}
          team={team}
          username={name}
          userId={userId}
        />
        <div className="user-item-info">
          <h3 className="user-title">{displayName}</h3>
          <p className="user-rank">{mainUser && 'Ranked'} #<span>{rank}</span></p>
        </div>
      </div>
      <div className="user-points">
        <span>{points}Pts</span>
      </div>
    </Tag>
  );
};

export default UserItem;
