import {useEffect, useRef, useState} from "react";
import useUserStore from "../store/userStore";
import {fetchScores, getGameStats} from "../services";
import {formatNumberWithCommas, getDisplayName, getPageCount} from "../helpers";
import UserItem from "../components/UserItem";
import Avatar from "../components/Avatar";
import Preloader from "../components/Preloader";
import starGold from "../assets/star-gold.png";
import starSilver from "../assets/star-silver.png";
import starBronze from "../assets/star-bronze.png";

const DISPLAY_USERS_PER_PAGE = 10;

const ScoresScreen = () => {
  const {user, loading, fetchUserOrCreate} = useUserStore();
  const [currentUserScore, setCurrentUserScore] = useState(0);
  const [currentUserRank, setCurrentUserRank] = useState(0);
  const [topScores, setTopScores] = useState([]);
  const [scores, setScores] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [isScoresLoading, setIsScoresLoading] = useState(false);
  const triggerRef = useRef(null);
  const initData = window.Telegram?.WebApp?.initData;
  const rankImages = {
    1: starGold,
    2: starSilver,
    3: starBronze
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting && !isScoresLoading && page < totalPages) {
        setPage((prevPage) => prevPage + 1);
      }
    },{
      rootMargin: '200px',
      threshold: 0.1
    });

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }

    return () => {
      if (triggerRef.current) {
        observer.unobserve(triggerRef.current)
      }
    }
  }, [isScoresLoading]);

  useEffect(() => {
    (async () => {
      const response = await fetchScores(1, 3);
      setTopScores(response.data.highScores);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsScoresLoading(true);
      const response = await fetchScores(page, DISPLAY_USERS_PER_PAGE);
      const totalCount = response.data.totalUsers;
      setTotalPages(getPageCount(totalCount, DISPLAY_USERS_PER_PAGE));
      const scoresToDisplay = page === 1 ? response.data.highScores.slice(3) : response.data.highScores;

      if (scoresToDisplay) {
        setScores((prev)=> [...prev, ...scoresToDisplay]);
      }
      setIsScoresLoading(false);
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      if (initData) {
        await fetchUserOrCreate(initData);
      } else {
        console.error('Telegram Web App script not loaded');
      }

      const gameStatsData = await getGameStats();
      setCurrentUserScore(gameStatsData.myPoints);
      setCurrentUserRank(gameStatsData.rank);
    })();
  }, [fetchUserOrCreate, initData]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="scores-inner">
      <div className="scores-main">
        <div className="my-info">
          <UserItem
            firstName={user.firstName}
            lastName={user.lastName}
            name={user.username}
            rank={currentUserRank}
            points={formatNumberWithCommas(currentUserScore)}
            team={user.team}
            userId={user.id}
            mainUser={true}
            element="div"
          />
        </div>

        <ul className="top-users">
          {topScores.slice(0, 3).map((topUser, index) => {
            const rankSrc = rankImages[index + 1] || null;
            return (
              (
                <li
                  key={`${user.username}-${index}`}
                  className={`top-user-item ${index + 1 === 1 ? 'first-place' : ''}`}
                  style={{
                    order: index + 1 === 1 ? 2 : index + 1 === 2 ? 1 : 3
                  }}
                >
                  <h3 className="user-title">
                    {getDisplayName(topUser.firstName, topUser.lastName, topUser.username)}
                  </h3>
                  <div className="user-points">
                <span data-content={`${formatNumberWithCommas(topUser.totalBalance)}Pts`}>
                  {formatNumberWithCommas(topUser.totalBalance)}Pts
                </span>
                  </div>
                  <div className="avatar-wrap">
                    <Avatar
                      firstName={topUser.firstName}
                      lastName={topUser.lastName}
                      name={user.username}
                      size={index + 1 === 1 ? 87 : 70}
                      team={topUser.team}
                      userId={topUser.telegramId}
                    />
                    <div className="user-rank">
                      <img src={rankSrc} alt={`Rank ${index + 1}`}/>
                      <span>{index + 1}</span>
                    </div>
                  </div>
                </li>
              )
            )
          })}
        </ul>
      </div>

      <ul className="users-list">
        {scores.map((user, index) => (
          <UserItem
            key={`${user.username}-${index}`}
            firstName={user.firstName}
            lastName={user.lastName}
            name={user.username}
            rank={index + 4}
            points={formatNumberWithCommas(user.totalBalance)}
            team={user.team}
            userId={user.telegramId}
          />
        ))}
        {isScoresLoading ? <Preloader/> : null}
      </ul>
      <div className="trigger" ref={triggerRef}></div>
    </div>
  );
};

export default ScoresScreen;
