import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import useUserStore from "../store/userStore";
import {API_URL} from "../constants";
import {getGameStats} from "../services";
import ButtonLarge from "../components/ButtonLarge";
import Modal from "../components/Modal";
import Preloader from "../components/Preloader";
import politicsStatic from "../assets/politics.json";
import coinImage from "../assets/goldcoin.png";
import {formatNumberWithCommas} from "../helpers";

const GAME_SETTINGS = {
  pointsWhacksOpposite: 1000,
  pointsWhacksOwn: 500
};

const COUNTDOWN_SETTINGS = {
  buttonCountdownTime: 3,
  gameCountdownTime: 30
};

const GameScreen = () => {
  const initData = window.Telegram?.WebApp?.initData;
  const {user, loading, fetchUserOrCreate} = useUserStore();
  const [politics, setPolitics] = useState([]);
  const [displayedPolitics, setDisplayedPolitics] = useState([]);
  const [cells, setCells] = useState(new Array(9));
  const [points, setPoints] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [buttonCountdown, setButtonCountdown] = useState(null);
  const [gameCountdown, setGameCountdown] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pointNotification, setPointNotification] = useState(null);
  const [hideHeads, setHideHeads] = useState({});
  const [highScore, setHighScore] = useState(0);
  const [lastGame, setLastGame] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (initData) {
        await fetchUserOrCreate(initData);
      } else {
        console.error('Telegram Web App script not loaded');
      }
    })();
  }, []);

  const sendFinishGameData = async () => {
    try {
      await axios.request({
        method: 'POST',
        baseURL: API_URL,
        url: '/game/finish',
        data: { score: points > 0 ? points : 0 },
        headers: {
          initdata: initData,
          'ngrok-skip-browser-warning': '69420'
        },
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  useEffect(() => {
    (async () => {
      const gameStatsData = await getGameStats();
      setHighScore(gameStatsData.highScore);
      setLastGame(gameStatsData.lastGameScore);
    })();
  }, []);

  useEffect(() => {
    setPolitics(politicsStatic);
    setCells([
      politics[4],
      null,
      politics[1],
      politics[5],
      null,
      politics[0],
      politics[3],
      null,
      politics[2]
    ]);
  }, [politics]);

  const startGame = () => {
    setDisplayedPolitics([]);
    setGameCountdown(COUNTDOWN_SETTINGS.gameCountdownTime);

    setTimeout(() => {
      if (politics.length > 0) {
        showRandomPolitics();
      }
    }, 100);
  };

  const handlePoliticClick = (politic) => {
    if (!politic || !isRunning) return;

    setHideHeads((prevStates) => ({
      ...prevStates,
      [politic.id]: true
    }));

    const isCorrectTeam = politic.team === user.team;
    const pointsChange = isCorrectTeam ? -GAME_SETTINGS.pointsWhacksOwn : GAME_SETTINGS.pointsWhacksOpposite;
    const notificationColor = isCorrectTeam ? "#f00" : "rgba(5, 255, 0, 0.5)";

    setPoints(prevPoints => prevPoints + pointsChange);
    setPointNotification({
      message: `${isCorrectTeam ? `-${GAME_SETTINGS.pointsWhacksOwn}` : `+${GAME_SETTINGS.pointsWhacksOpposite}`}`,
      color: notificationColor,
      id: politic.id
    });

    setTimeout(() => {
      setPointNotification(null);
    }, 1000);
  };

  const showRandomPolitics = () => {
    if (politics.length === 0) {
      return;
    }

    setHideHeads({});

    const numOfPoliticsToShow = Math.floor(Math.random() * 4) + 1;
    const randomIndexes = [];

    while (randomIndexes.length < numOfPoliticsToShow) {
      const randomIndex = Math.floor(Math.random() * politics.length);

      if (!randomIndexes.includes(randomIndex)) {
        randomIndexes.push(randomIndex);
      }
    }

    const getRandomDisplayTime = (minTime, maxTime, step) => {
      const possibleValues = (maxTime - minTime) / step + 1;
      const randomStep = Math.floor(Math.random() * possibleValues);
      return minTime + randomStep * step;
    }

    const getDisplayTime = (gameCountdown) => {
      if (gameCountdown <= 10) {
        return getRandomDisplayTime(1250, 1750, 250);
      } else {
        return getRandomDisplayTime(1500, 2000, 500);
      }
    };

    const selectedPolitics = randomIndexes.map(index => {
      return {
        ...politics[index],
        displayTime: getDisplayTime(gameCountdown)
      };
    });

    const cloneSelectedPolitics = [...selectedPolitics];
    cells.fill(null);

    while (cloneSelectedPolitics.length) {
      const cellIndex = Math.floor(Math.random() * 9);

      if (!cells[cellIndex]) {
        cells[cellIndex] = cloneSelectedPolitics.pop();
      }
    }

    setDisplayedPolitics(selectedPolitics);
  }

  const handleStartClick = () => {
    cells.fill(null);
    setButtonCountdown(COUNTDOWN_SETTINGS.buttonCountdownTime);
    setIsRunning(true);
  };

  useEffect(() => {
    if (buttonCountdown === 0) {
      startGame();
    }

    if (buttonCountdown !== null && buttonCountdown > 0) {
      const timer = setTimeout(() => setButtonCountdown(buttonCountdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [buttonCountdown]);

  useEffect(() => {
    if (gameCountdown === null) {
      return;
    }

    if (gameCountdown === 0) {
      setDisplayedPolitics([]);
      cells.fill(null);

      if (points < 0) {
        setPoints(0);
      }

      sendFinishGameData();
      setIsModalOpen(true);
      return;
    }

    const gameTimer = setTimeout(() => setGameCountdown(gameCountdown - 1), 1000);

    return () => {
      clearTimeout(gameTimer);
    };
  }, [gameCountdown]);

  useEffect(() => {
    if (
      gameCountdown == null ||
      gameCountdown === 0 ||
      gameCountdown % 2 !== COUNTDOWN_SETTINGS.gameCountdownTime % 2
    ) {
      return;
    }

    showRandomPolitics();
  }, [gameCountdown, politics]);

  const handlePlayAgain = () => {
    setIsModalOpen(false);
    navigate(0);
  };

  const handleViewPoints = () => {
    setIsModalOpen(false);
    navigate("/tabs/account");
  };

  if (loading || !user) {
    return <Preloader />;
  }

  return (
    <>
      <div className={`game-container-inner ${isRunning ? 'started' : ''}`}>
        <div className="board">
          <div className="board-inner">
            <div className="board-title">
              <div className="board-title-main">
                <h2>Press start to begin</h2>
              </div>
              <div className="board-title-active">
                <h2>Points Collected</h2>
                <div className="points">
                  <img src={coinImage} alt="Coin" width="30" height="26"/>
                  <span>{points > 0 ? `+${points}` : points}</span>
                </div>
              </div>
            </div>
            <div className="board-info">
              <div className="board-info-column">
                <span className="board-info-title">High Score:</span>
                <span className="board-info-value">{formatNumberWithCommas(highScore)} Pts</span>
              </div>
              <div className="board-info-column">
                <span className="board-info-title">Last Game:</span>
                <span className="board-info-value">{formatNumberWithCommas(lastGame)} Pts</span>
              </div>
            </div>
          </div>
          <div className="board-teams-coins">
            <div className="board-team-column">
              <p className="board-team">
                Team Blue <span>{user.team === 'blue' ? `-${GAME_SETTINGS.pointsWhacksOwn}` : `+${GAME_SETTINGS.pointsWhacksOpposite}`}</span>
              </p>
            </div>
            <div className="board-team-column">
              <p className="board-team red">
                Team Red <span>{user.team === 'blue' ? `+${GAME_SETTINGS.pointsWhacksOpposite}` : `-${GAME_SETTINGS.pointsWhacksOwn}`}</span>
              </p>
            </div>
          </div>
        </div>

        <div className="politics-grid">
          {cells.map((politic, index) => {
            return politic ? (
              <div
                key={`politic-${politic.id}`}
                className={`cell politic ${politic.team} ${hideHeads[politic.id] ? "hide" : ""}`}
              >
                {pointNotification && pointNotification.id === politic.id && (
                  <div className="politic-notifications" style={{background: pointNotification.color}}>
                    <img src={coinImage} alt="Coin" width="22" height="19"/>
                    <span>{pointNotification.message}</span>
                  </div>
                )}
                <div
                  className="politic-head"
                  onClick={() => handlePoliticClick(politic)}
                  style={{
                    animation: `moveY ${politic.displayTime}ms ease-in-out forwards`,
                    cursor: isRunning ? 'pointer' : 'default'
                  }}
                >
                  <img src={politic.photo} alt="head"/>
                </div>
              </div>
            ) : (
              <div key={index} className="cell"></div>
            );
          })}
        </div>

        {gameCountdown !== null ? (
          <div className="game-timer">
            <ButtonLarge additionalClass={`button-sand ${gameCountdown > 0 ? '' : 'times-up'}`}>
              {gameCountdown > 0 ? `${gameCountdown}s` : 'TIMES UP!'}
            </ButtonLarge>
          </div>
        ) : (
        !isRunning ? (
          <ButtonLarge onClick={handleStartClick}>Start</ButtonLarge>
        ) : (
          <ButtonLarge additionalClass="button-timer">
            Starting in <span>{buttonCountdown}s</span>
          </ButtonLarge>
        )
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onPlayAgain={handlePlayAgain}
        onViewPoints={handleViewPoints}
      >
        <h2>Points You’ve Collected</h2>
        <div className="points">
          <img src={coinImage} alt="Coin" width="47" height="41"/>
          <span data-content={points > 0 ? `+${points}` : points}>
            {points > 0 ? `+${points}` : points}
          </span>
        </div>
        <p>Points will be added to your account balance</p>
      </Modal>
    </>
  );
};

export default GameScreen;
