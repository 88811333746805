import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {API_URL, telegramLink} from "../constants";
import Footer from '../components/Footer';
import useUserStore from "../store/userStore";

const SubscribeScreen = () => {
    const {isElectionsFinished} = useUserStore();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(false);

    const validateSubscription = () => {
        setErrorMessage(null);
        axios.request({
            method: 'GET',
            baseURL: API_URL,
            url: '/telegram-users/verify-subscription',
            headers: {
                initdata: window.Telegram.WebApp.initData,
                'ngrok-skip-browser-warning': '69420'
            }
        }).catch((error) => {
            setErrorMessage(error);
            console.error("Error fetching telegram data:", error);
            return false;
        }).then(res => {
            
            if (res.data) {
              navigate('/congratulations')
            } else {
              setErrorMessage("You need to subscribe to our channel!");
            }
        });
    };

  return (
    <div className="container">
      <div className="task-cointainer">
        <h3>WHACK’EM Holder</h3>
        {isElectionsFinished ? (
          <>
            <p>Activate Your Game Card! Complete the task below to get your in-game Whack'em Card and start earning!</p>
            <p>Earn 10,000 Airdrop Points when you finish the task and join the fun!</p>
          </>
        ) : (
          <>
            <p>The Ballot is Open – Register for your in-game Voters Card by Completing the Task below!</p>
            <p>Earn an additional 10,000 Airdrop Points from Casting your Vote!</p>
          </>
        )}
      </div>
      <div className="subscribe-container">
        <h2>Welcome to Whack'Em</h2>
        <div className="white-container">
          <p>Instantly Claim 10,000 Airdrop Points, Subscribe to the Whack’em Telegram Channel</p>
              <a target='_blank' href={telegramLink} rel="noreferrer">
                  <button className="orange-button">Subscribe</button>
              </a>
              <button className="blue-button" onClick={validateSubscription}>Verify Subscription</button>
              <p className="errorMessage">{errorMessage}</p>
          </div>
          {isElectionsFinished ? (
            <p className="hint">Complete this task to unlock your Whack'em Game Profile and get ready to participate in
              exclusive events, including challenges, giveaways, and more!</p>
          ) : (
            <p className="hint">Complete this Task to register for your in-game Vote and get ready to cast your choice for the next US President—Trump or Harris!</p>
          )}
      </div>
      <Footer/>
    </div>
  );
};

export default SubscribeScreen;
